"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosEntranceFeeType = exports.PosSource = exports.PosPeriodType = exports.PosPeriodStatus = exports.PosConsumptionMode = exports.PosStatus = exports.PosChannel = exports.PosOrderItemType = exports.OrderItemState = exports.PosOrderFlagName = exports.PosCustomizationUserChoice = exports.PosGrantType = exports.PosOrderApiVersion = void 0;
var PosOrderApiVersion;
(function (PosOrderApiVersion) {
    PosOrderApiVersion[PosOrderApiVersion["V1"] = 0] = "V1";
    PosOrderApiVersion[PosOrderApiVersion["V2"] = 1] = "V2";
    PosOrderApiVersion[PosOrderApiVersion["V3"] = 2] = "V3";
    PosOrderApiVersion[PosOrderApiVersion["V4"] = 3] = "V4";
    PosOrderApiVersion[PosOrderApiVersion["V5"] = 4] = "V5";
})(PosOrderApiVersion = exports.PosOrderApiVersion || (exports.PosOrderApiVersion = {}));
var PosGrantType;
(function (PosGrantType) {
    PosGrantType["ABSOLUTE"] = "ABSOLUTE";
    PosGrantType["RATE"] = "RATE";
})(PosGrantType = exports.PosGrantType || (exports.PosGrantType = {}));
var PosCustomizationUserChoice;
(function (PosCustomizationUserChoice) {
    PosCustomizationUserChoice["ADD"] = "Add";
    PosCustomizationUserChoice["REMOVE"] = "Remove";
    PosCustomizationUserChoice["CHOICE"] = "Choice";
})(PosCustomizationUserChoice = exports.PosCustomizationUserChoice || (exports.PosCustomizationUserChoice = {}));
var PosOrderFlagName;
(function (PosOrderFlagName) {
    PosOrderFlagName["ENTRANCE_FEE_ID"] = "ENTRANCE_FEE.ID";
    PosOrderFlagName["ENTRANCE_FEE_CODE"] = "ENTRANCE_FEE.CODE";
    PosOrderFlagName["ENTRANCE_FEE_LABEL_TICKET"] = "ENTRANCE_FEE.LABEL_TICKET";
    PosOrderFlagName["ENTRANCE_FEE_AMOUNT"] = "ENTRANCE_FEE.AMOUNT";
    PosOrderFlagName["ENTRANCE_FEE_TAX"] = "ENTRANCE_FEE.TAX";
    PosOrderFlagName["ENTRANCE_FEE_IS_MANUAL"] = "ENTRANCE_FEE.IS_MANUAL";
    PosOrderFlagName["ENTRANCE_FEE_STRATEGY"] = "ENTRANCE_FEE.STRATEGY";
    PosOrderFlagName["GRANT_ID"] = "GRANT.ID";
    PosOrderFlagName["GRANT_CODE"] = "GRANT.CODE";
    PosOrderFlagName["GRANT_LABEL_TICKET"] = "GRANT.LABEL_TICKET";
    PosOrderFlagName["GRANT_AMOUNT"] = "GRANT.AMOUNT";
    PosOrderFlagName["GRANT_STRATEGY"] = "GRANT.STRATEGY";
    PosOrderFlagName["LOYALTY_CARD_ID"] = "LOYALTY.CARD_ID";
    PosOrderFlagName["LOYALTY_CARD_NUMBER"] = "LOYALTY.CARD_NUMBER";
    PosOrderFlagName["LOYALTY_USE_ROYALTY"] = "LOYALTY.USE_ROYALTY";
    PosOrderFlagName["LOYALTY_CURRENT_BALANCE"] = "LOYALTY.CURRENT_BALANCE";
})(PosOrderFlagName = exports.PosOrderFlagName || (exports.PosOrderFlagName = {}));
var OrderItemState;
(function (OrderItemState) {
    OrderItemState["PAID"] = "Paid";
})(OrderItemState = exports.OrderItemState || (exports.OrderItemState = {}));
var PosOrderItemType;
(function (PosOrderItemType) {
    PosOrderItemType["PRODUCT"] = "Product";
    PosOrderItemType["GRANT"] = "Grant";
    PosOrderItemType["ENTRANCE_FEE"] = "EntranceFee";
    PosOrderItemType["ADMISSION_FEE"] = "AdmissionFee";
    PosOrderItemType["ROYALTY_FEE"] = "RoyaltyFee";
    PosOrderItemType["LOYALTY"] = "Loyalty";
    PosOrderItemType["COMPENSATION"] = "Compensation";
})(PosOrderItemType = exports.PosOrderItemType || (exports.PosOrderItemType = {}));
var PosChannel;
(function (PosChannel) {
    PosChannel["Kiosk"] = "Kiosk";
    PosChannel["Web"] = "Web";
    PosChannel["Pos"] = "Pos";
    PosChannel["UberEats"] = "UberEats";
    PosChannel["Deliveroo"] = "Deliveroo";
    PosChannel["JustEat"] = "JustEat";
})(PosChannel = exports.PosChannel || (exports.PosChannel = {}));
var PosStatus;
(function (PosStatus) {
    PosStatus["Validated"] = "Validated";
    PosStatus["Paid"] = "Paid";
    PosStatus["PaidFromExternal"] = "PaidFromExternal";
    PosStatus["Cancelled"] = "Cancelled";
})(PosStatus = exports.PosStatus || (exports.PosStatus = {}));
var PosConsumptionMode;
(function (PosConsumptionMode) {
    PosConsumptionMode["Delivery"] = "Delivery";
    PosConsumptionMode["PickUp"] = "PickUp";
    PosConsumptionMode["EatIn"] = "EatIn";
    PosConsumptionMode["Drive"] = "Drive";
})(PosConsumptionMode = exports.PosConsumptionMode || (exports.PosConsumptionMode = {}));
var PosPeriodStatus;
(function (PosPeriodStatus) {
    PosPeriodStatus["Draft"] = "Draft";
    PosPeriodStatus["PartiallyCompleted"] = "PartiallyCompleted";
    PosPeriodStatus["Complete"] = "Complete";
})(PosPeriodStatus = exports.PosPeriodStatus || (exports.PosPeriodStatus = {}));
var PosPeriodType;
(function (PosPeriodType) {
    PosPeriodType["Shift"] = "Shift";
    PosPeriodType["Day"] = "Day";
    PosPeriodType["Week"] = "Week";
    PosPeriodType["Month"] = "Month";
    PosPeriodType["Year"] = "Year";
})(PosPeriodType = exports.PosPeriodType || (exports.PosPeriodType = {}));
var PosSource;
(function (PosSource) {
    PosSource["Pos"] = "ZenPOS";
    PosSource["API"] = "InnovOrder";
})(PosSource = exports.PosSource || (exports.PosSource = {}));
var PosEntranceFeeType;
(function (PosEntranceFeeType) {
    PosEntranceFeeType["EntranceFee"] = "ENTRANCE";
    PosEntranceFeeType["AdmissionFee"] = "AdmissionFee";
    PosEntranceFeeType["RoyaltyFee"] = "RoyaltyFee";
    PosEntranceFeeType["AdmissionFeeOldVersion"] = "ADMISSION_FEE";
    PosEntranceFeeType["RoyaltyFeeOldVersion"] = "ROYALTY_FEE";
})(PosEntranceFeeType = exports.PosEntranceFeeType || (exports.PosEntranceFeeType = {}));
