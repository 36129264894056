"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.posOrderFeesAdapter = exports.mapPosOrderFeeToFee = void 0;
var bignumber_js_1 = require("bignumber.js");
var order_detail_types_1 = require("../../types/order-detail.types");
var utils_1 = require("../../utils/utils");
var types_1 = require("./types");
var deliveryFeeRegexp = /(delivery fee)/;
var serviceChargeRegexp = /(service charge)/;
var entranceFeeFromOrderFlagsAdapter = function (orderItem, orderFlags) {
    return orderFlags.reduce(function (_entranceFee, orderFlag) {
        // eslint-disable-next-line default-case
        switch (orderFlag.name) {
            case types_1.PosOrderFlagName.ENTRANCE_FEE_AMOUNT: {
                _entranceFee.value = parseInt(orderFlag.value, 10);
                break;
            }
            case types_1.PosOrderFlagName.ENTRANCE_FEE_ID: {
                _entranceFee.id = orderFlag.value;
                _entranceFee.applyScopeId = "".concat(orderFlag.value);
                break;
            }
        }
        return _entranceFee;
    }, {
        label: orderItem.ticketName,
        id: null,
        taxRate: new bignumber_js_1.BigNumber(orderItem.tax).shiftedBy(4).toNumber(),
        type: order_detail_types_1.FEE_TYPE.ENTRANCE,
        value: 0,
        quantity: 1,
        additionalData: orderItem,
    });
};
var mapPosOrderFeeToFee = function (feeType) {
    switch (feeType) {
        case types_1.PosEntranceFeeType.RoyaltyFee:
        case types_1.PosEntranceFeeType.RoyaltyFeeOldVersion:
            return order_detail_types_1.FEE_TYPE.ROYALTY_FEE;
        case types_1.PosEntranceFeeType.AdmissionFee:
        case types_1.PosEntranceFeeType.AdmissionFeeOldVersion:
            return order_detail_types_1.FEE_TYPE.ADMISSION_FEE;
        case types_1.PosEntranceFeeType.EntranceFee:
            return order_detail_types_1.FEE_TYPE.ENTRANCE;
        default:
            return (0, utils_1.assertUnreachable)(feeType);
    }
};
exports.mapPosOrderFeeToFee = mapPosOrderFeeToFee;
var posOrderFeesAdapterV1 = function (_a, digitsRange) {
    var orderItems = _a.orderItems, orderFlags = _a.orderFlags;
    var orderDetailFees = [];
    var handledFees = new Set();
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_1.PosOrderItemType.ENTRANCE_FEE; })
        // eslint-disable-next-line array-callback-return
        .map(function (orderItem) {
        if (!handledFees.has(orderItem.name)) {
            orderDetailFees.push(entranceFeeFromOrderFlagsAdapter(orderItem, orderFlags));
            handledFees.add(orderItem.name);
        }
    });
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_1.PosOrderItemType.PRODUCT; })
        .map(function (orderItem) {
        var _a = deliveryFeeRegexp.exec(orderItem.ticketName) || [false, null], match = _a[0], label = _a[1];
        if (match && !handledFees.has(label)) {
            orderDetailFees.push({
                label: label.toString(),
                taxRate: new bignumber_js_1.BigNumber(orderItem.tax).shiftedBy(4).toNumber(),
                type: order_detail_types_1.FEE_TYPE.DELIVERY,
                applyScopeId: orderItem.applyScopeId,
                value: new bignumber_js_1.BigNumber(orderItem.unitPriceWithTaxIncluded).shiftedBy(digitsRange).toNumber(),
                quantity: 1,
                additionalData: orderItem,
            });
            handledFees.add(label);
        }
        return orderItem;
    })
        // eslint-disable-next-line array-callback-return
        .map(function (orderItem) {
        var _a = serviceChargeRegexp.exec(orderItem.ticketName) || [false, null], match = _a[0], label = _a[1];
        if (match && !handledFees.has(label)) {
            orderDetailFees.push({
                label: label.toString(),
                id: null,
                taxRate: new bignumber_js_1.BigNumber(orderItem.tax).shiftedBy(4).toNumber(),
                type: order_detail_types_1.FEE_TYPE.SERVICE,
                applyScopeId: orderItem.applyScopeId,
                value: new bignumber_js_1.BigNumber(orderItem.unitPriceWithTaxIncluded).shiftedBy(digitsRange).toNumber(),
                quantity: 1,
                additionalData: orderItem,
            });
            handledFees.add(label);
        }
    });
    return orderDetailFees;
};
var posOrderFeesAdapterV2 = function (_a, digitsRange) {
    var orderItems = _a.orderItems, _b = _a.entranceFees, entranceFees = _b === void 0 ? [] : _b;
    var orderDetailFees = [];
    var handledFees = new Set();
    // eslint-disable-next-line array-callback-return
    entranceFees.map(function (entranceFee) {
        var _a;
        if (!handledFees.has(entranceFee.code)) {
            // eslint-disable-next-line no-unused-vars
            var _b = orderItems.filter(function (_orderItem) {
                return (_orderItem.orderItemType === types_1.PosOrderItemType.ENTRANCE_FEE ||
                    _orderItem.orderItemType === types_1.PosOrderItemType.ADMISSION_FEE ||
                    _orderItem.orderItemType === types_1.PosOrderItemType.ROYALTY_FEE) &&
                    _orderItem.ticketName === entranceFee.labelTicket;
            }), orderItem = _b[0], _1 = _b.slice(1);
            if (orderItem) {
                orderDetailFees.push({
                    label: entranceFee.labelTicket,
                    id: entranceFee.entranceFeeId,
                    applyScopeId: "".concat(entranceFee.entranceFeeId),
                    taxRate: entranceFee.vat,
                    type: entranceFee.type ? (0, exports.mapPosOrderFeeToFee)(entranceFee.type) : order_detail_types_1.FEE_TYPE.ENTRANCE,
                    value: entranceFee.amount,
                    quantity: (_a = entranceFee.appliedQuantity) !== null && _a !== void 0 ? _a : 1,
                    additionalData: __assign(__assign({}, entranceFee), { state: orderItem.state, originalUnitPrice: orderItem.originalUnitPrice, cancelReason: orderItem.cancelReason }),
                });
            }
            handledFees.add(entranceFee.code);
        }
    });
    orderItems
        .filter(function (orderItem) { return orderItem.orderItemType === types_1.PosOrderItemType.PRODUCT; })
        .map(function (orderItem) {
        var _a = deliveryFeeRegexp.exec(orderItem.ticketName) || [false, null], match = _a[0], label = _a[1];
        if (match && !handledFees.has(label)) {
            orderDetailFees.push({
                label: label.toString(),
                taxRate: new bignumber_js_1.BigNumber(orderItem.tax).shiftedBy(4).toNumber(),
                type: order_detail_types_1.FEE_TYPE.DELIVERY,
                applyScopeId: orderItem.applyScopeId,
                value: new bignumber_js_1.BigNumber(orderItem.unitPriceWithTaxIncluded).shiftedBy(digitsRange).toNumber(),
                quantity: 1,
                additionalData: orderItem,
            });
            handledFees.add(label);
        }
        return orderItem;
    })
        // eslint-disable-next-line array-callback-return
        .map(function (orderItem) {
        var _a = serviceChargeRegexp.exec(orderItem.ticketName) || [false, null], match = _a[0], label = _a[1];
        if (match && !handledFees.has(label)) {
            orderDetailFees.push({
                label: label.toString(),
                id: null,
                taxRate: new bignumber_js_1.BigNumber(orderItem.tax).shiftedBy(4).toNumber(),
                type: order_detail_types_1.FEE_TYPE.SERVICE,
                applyScopeId: orderItem.applyScopeId,
                value: new bignumber_js_1.BigNumber(orderItem.unitPriceWithTaxIncluded).shiftedBy(digitsRange).toNumber(),
                quantity: 1,
                additionalData: orderItem,
            });
            handledFees.add(label);
        }
    });
    return orderDetailFees;
};
function generateApplyScopeId(orderItem, index) {
    orderItem.applyScopeId = "".concat(index, "-").concat(orderItem.sourceId);
    orderItem.index = index;
    return orderItem;
}
// eslint-disable-next-line consistent-return
function posOrderFeesAdapter(_a, digitsRange, posApiVersion) {
    var _b = _a.orderItems, orderItems = _b === void 0 ? [] : _b, entranceFees = _a.entranceFees, _c = _a.orderFlags, orderFlags = _c === void 0 ? [] : _c;
    orderItems = orderItems.map(generateApplyScopeId);
    switch (posApiVersion) {
        case types_1.PosOrderApiVersion.V1: {
            return posOrderFeesAdapterV1({ orderItems: orderItems, orderFlags: orderFlags }, digitsRange);
        }
        case types_1.PosOrderApiVersion.V5:
        case types_1.PosOrderApiVersion.V4:
        case types_1.PosOrderApiVersion.V3:
        case types_1.PosOrderApiVersion.V2: {
            return posOrderFeesAdapterV2({ orderItems: orderItems, orderFlags: orderFlags, entranceFees: entranceFees }, digitsRange);
        }
        default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error("Undefined PosOrderApiVersion ".concat(posApiVersion));
    }
}
exports.posOrderFeesAdapter = posOrderFeesAdapter;
